import { User } from './models/user.model';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService,
              private myRoute: Router) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      if (this.auth.getToken()) {
      const user = new User(this.auth.getLoggedUser());
      const commonRoutes = [];
      const requestPath  = next.url;


      return true;
    } else {
      this.myRoute.navigate(['login']);
      return false;
    }
  }

}

import { LocalStorageService } from 'ngx-store';
import { SelectComponent } from './select/select.component';

import { JsonapiService } from 'src/app/services/jsonapi.service';
import { ErrorResponce } from './../../models/jsonapi.model';
import { environment } from './../../../environments/environment';
import { Token } from './../../models/token.model';
import { Credentials, IUser, User } from './../../models/user.model';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  form;
  ripristinoform;
  public error;
  public tokenExpire;
  public showpass = false;
  public version = null;
  public loginForm = 1;
  constructor(
    private fb: FormBuilder,
    private myRoute: Router,
    private auth: AuthService,
    private snackBar: MatSnackBar,
    private jsonapiservice: JsonapiService,
    private localstorage: LocalStorageService,
    public dialog: MatDialog,
  ) {
    this.form = fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    this.ripristinoform = fb.group({
      email: ['', [Validators.required, Validators.email]]
    });

  }
  ngOnInit() {
    this.version = environment.version;
  }

  showSelect(object) {
    const dialogRef = this.dialog.open(SelectComponent, {
     //   height: '400px',
        width: '600px',
        data: object,
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  restore() {
    this.loginForm = 2;
  }

  doResetPassword() {
    if (this.ripristinoform.valid) {
      const data = {
        username: this.ripristinoform.value.email
      };
      this.jsonapiservice.postEntityCustomPath(data, 'auth/restore').subscribe((success: any) => {
        this.openSnackBar('Una email con la nuova password è stata mandata all\'indirizzo da te indicato', 'OK');
        this.loginForm = 1;
      }, (error) => {
        if (error.status === 421) {
          this.openSnackBar('La email da te inserita non è registrata, impossibile eseguire il ripristino', 'OK');
        }
      });
    } else {
      this.openSnackBar('Email non valida, verifica di inserire un indirizzo email valido', 'OK');
    }
  }


  login() {
    if (this.form.valid) {
      this.auth.login({ username: this.form.value.email, password: this.form.value.password }).subscribe((data: Token) => {
        if (data.access_token) {
          const token = data.access_token;
          this.auth.sendToken(token);
          this.auth.getUser().subscribe((user: IUser) => {
            const loggedUser = new User(user);
            this.auth.setUser(loggedUser);
            this.localstorage.set('sedeselezionata','');
            if (loggedUser.data.attributes.conti.length > 1) {
              this.showSelect(loggedUser.data.attributes.conti);
            } else {
              window.location.href = '/';
            } 
          });
        }
      },
        (error: ErrorResponce) => {
          this.openSnackBar("Nome utente e/o password non corrette", "ok");
       //   this.jsonapiservice.handlingError(error);
        }
      );
      //
    } else {
        this.openSnackBar("Informazioni non complete, login fallito", "ok");
    }
  }
}

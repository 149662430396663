import { AuthService } from './auth.service';
import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Response } from '@angular/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take, timeout } from 'rxjs/operators';
import { ErrorResponce } from './../models/jsonapi.model';
import { Router } from '@angular/router';

export interface Filter {
  name: string;
  value: any;
}


@Injectable()
export class JsonapiService {

  API_BASE: string = environment[environment.env].base_url + '/' + environment[environment.env].version;

  private errorMessage = '';
  constructor(
    private injector: Injector,
    // tslint:disable-next-line: no-shadowed-variable
    private AuthService: AuthService,
    private router: Router
  ) {
  }
  /**
   *
   * @param resource
   * @param page
   * @param pageSize
   */
  public getCollectionPaginated(resource: string, page: number = 1, pageSize: number = 20, filters?: Array<Filter>, orderby?: string) {
    const ServiceHeaders = this.AuthService.getJsonApiHeaders();
    let payload = new HttpParams();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    }
    payload = payload.append('page[number]', page.toString());
    payload = payload.append('page[size]', pageSize.toString());
    console.log(payload);
    const options = { headers: ServiceHeaders, params: payload };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${resource}`, options);
  }

  /**
   * esegue una GET una route custom
   * @param $route
   */
  public customGetRequest($route) {
    const ServiceHeaders = this.AuthService.getJsonApiHeaders();
    const payload = new HttpParams();
    const options = { headers: ServiceHeaders, params: payload };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${$route}`, options);
  }

  public resetPassword(uri: string, emailAddress: string) {
    const payload =
    {
      email: emailAddress
    };
    return this.injector.get(HttpClient).post(uri, payload);
  }


  public getCollection(resource: string, filters?: Array<Filter>, orderby?: string) {
    const ServiceHeaders = this.AuthService.getJsonApiHeaders();
    let payload = new HttpParams();
    if (orderby) {
      payload = payload.append(`sort`, orderby);
    }
    if (filters && filters.length > 0) {
      filters.forEach((filter: Filter) => {
        payload = payload.append(`filter[${filter.name}]`, filter.value);
      });
    }
    const options = { headers: ServiceHeaders, params: payload };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${resource}`, options);
  }

  /**
   *
   * @param resource
   * @param id
   */
  public getEntity(resource: string, id) {
    const ServiceHeaders = this.AuthService.getJsonApiHeaders();
    const options = { headers: ServiceHeaders };
    return this.injector.get(HttpClient).get(`${this.API_BASE}/${resource}/${id}`, options);
  }

  /**
   *
   * @param resource
   * @param id
   * @param data
   */
  public updateEntity(resource: string, id, data) {
    const ServiceHeaders = this.AuthService.getJsonApiHeaders();
    const options = { headers: ServiceHeaders };
    return this.injector.get(HttpClient).patch(`${this.API_BASE}/${resource}/${id}`, data, options);
  }

  /**
   *
   * @param resource
   * @param data
   */
  public postEntity(resource: string, data, addictionalPath?: string) {
    const ServiceHeaders = this.AuthService.getJsonApiHeaders();
    const options = { headers: ServiceHeaders };
    let uri = '';
    if (addictionalPath) {
      uri = `${this.API_BASE}/${resource}/${addictionalPath}`;
    } else {
      uri = `${this.API_BASE}/${resource}`;
    }

    return this.injector.get(HttpClient).post(uri, data, options);
  }

  /**
   * permette di eseguire una post su una custom path.
   * i data postati possono non essere jsonapi.
   * @param data
   * @param path
   */
  public postEntityCustomPath(data, path?: string) {
    const ServiceHeaders = this.AuthService.getJsonApiHeaders();
    const options = { headers: ServiceHeaders };
    const uri = environment[environment.env].base_url + `/${path}`;
    return this.injector.get(HttpClient).post(uri, data, options);
  }


  /**
   * delete an entity
   * @param resource
   * @param id
   */
  public deleteEntity(resource: string, id) {
    const ServiceHeaders = this.AuthService.getJsonApiHeaders();
    const options = { headers: ServiceHeaders };
    const uri = `${this.API_BASE}/${resource}/${id}`;
    return this.injector.get(HttpClient).delete(uri, options);
  }

  /** Error Handling */
  public handlingError(error: ErrorResponce) {
    console.error(error);
  };

}

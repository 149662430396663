import { element } from 'protractor';
import { CertificatesComponent } from './../certificates/certificates.component';
import { Paginator } from './../../models/paginator.model';
import { CollectionPaginated } from './../../models/jsonapi.model';
import { JsonapiService, Filter } from './../../services/jsonapi.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { ThemePalette } from '@angular/material/core';
import { MatSort, MatTableDataSource, Sort, MatDialog, MatDatepickerInputEvent, MatSnackBar } from '@angular/material';
import { LocalStorageService } from 'ngx-store';
import * as moment from 'moment';
import { format } from 'url';
import { FormControl } from '@angular/forms';
import { DownloadService } from 'src/app/services/Download.service';
import { SelectionModel } from '@angular/cdk/collections';
import * as _ from 'lodash';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css']
})
export class BodyComponent implements OnInit {
  pageSize = 10;
  page = 1;
  searchField = '';
  oldSearchField = '';

  color: ThemePalette = 'warn';
  recordSet: any = [];
  jsonapipaginator: Paginator;
  displayedColumns: string[] = [
    'indentificazione',
    'descrizione',
    'sn',
    'numero_certificato',
    'dta',
    'icon'
  ];
  downloading = false;
  firstLogin = true;
  user = null;
  dataSource = new MatTableDataSource<any>();
  loading = false;
  dtaStart = null;
  dtaStop  = null;
  rows = [];
  status = false;
  constructor(
    private jsonapiservice: JsonapiService,
    private localStorage: LocalStorageService,
    public dialog: MatDialog,
    private downloadservice: DownloadService,
    private snackBar: MatSnackBar
  ) { }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit() {
    this.firstLogin = this.localStorage.get('firstLogin');
    this.user = this.localStorage.get('user');

    /**
     * calcolo le date
     */
    const dtastart =  moment().subtract(3, 'months');
    const dtastop  =  moment();

    this.dtaStart = new FormControl( dtastart.toDate() );
    this.dtaStop  = new FormControl( dtastop.toDate() );

    if (this.firstLogin !== null) {
      this.loadData(1, this.pageSize, [], null);
    }

  }
  search() {
    
    if (!this.loading) {
      const filters: Array<Filter> = [];
      if (this.searchField !== '') {
        this.oldSearchField =this.searchField.toUpperCase();
        filters.push({ name: 'search', value: this.searchField.toUpperCase() });
      }
      this.loadData(1, this.pageSize, filters, null);
    }
  }
  /**
   * Mostra i certificati per strumento
   * @param object
   */
  showCertificates(object) {
    const dialogRef = this.dialog.open(CertificatesComponent, {
     //   height: '400px',
        width: '600px',
        data: object,
    });
  }
  /**
   *
   * @param code
   * @param ext
   * @param downloadble
   */
  public download(code, ext) {

    this.downloadservice.downloadCertificate(code).subscribe(
      (responseData: any) => {
      const a = document.createElement('a');
      const blob = new Blob([responseData], { type: responseData.type });
      a.href = URL.createObjectURL(blob);
      a.download = `${code}.${ext}`;
      a.click();
    }, (error) => {
        if (error.status === 421) {
          this.openSnackBar('Il file allegato al certificato non è stato trovato', 'OK');
        }
    });
  }


  /**
   * salva lo stato dell'ultimo login utente
   */
  saveStateFirstLogin() {
    this.localStorage.set('firstLogin', true);
    this.firstLogin = true;
    this.loadData(1, this.pageSize, [], null);
  }

  getIcon(ext: string) {
    return '/assets/file-iconset/32px/' + ext + '.png';
  }

  loadData(page, pageSize, filters: Array<Filter>, orderby?: string) {
  
    if (!this.loading) {
    const range = moment(this.dtaStart.value).format('YYYYMMDD') + '-' + moment(this.dtaStop.value).format('YYYYMMDD');
    filters.push({ name: 'range', value: range });
     if (!orderby) {
      orderby = '-dta';
    } 
    this.loading = true;
    this.dataSource.paginator = this.paginator;
    this.jsonapiservice.getCollectionPaginated('tools', page, pageSize, filters, orderby).subscribe((data: CollectionPaginated) => {
      this.loading = false;
      this.recordSet = [];
      this.jsonapipaginator = data.meta.page;
      data.data.forEach(item => {
        this.recordSet.push({
          id: item.id,
          key: item.attributes.mykey,
          indentificazione: item.attributes.indentificazione,
          descrizione: item.attributes.descrizione,
          numero_certificato: item.attributes.numero_certificato,
          modello: item.attributes.modello,
          sn: item.attributes.sn,
          dta: item.attributes.dta,
          icon: item.attributes.icon,
          locked: item.attributes.locked,
          new : item.attributes.new,
          selected: false
         // taratura: item.attributes.taratura
        });
      }); // end of foreach
      this.dataSource.data = this.recordSet;
      this.loading = false;
      if (this.oldSearchField !==this.searchField.toUpperCase()) {
        this.oldSearchField = this.searchField;
        this.search();
      }
    });
  }
  }

  log(e) {
    console.log(e);
  }

  changePage($event) {
    const filters: Array<Filter> = [];
    if (this.searchField !== '') {
      filters.push({ name: 'search', value: this.searchField });
    }
    this.page = $event.pageIndex + 1;
    this.pageSize = $event.pageSize;
    this.loadData(this.page, this.pageSize, filters, null);
  }

  sortData($event) {
    const orderBy = $event.active;
    const direction = $event.direction === 'asc' ? '' : '-';
  //  window.localStorage.setItem('orderDoc', direction);
    const filters: Array<Filter> = [];
    if (this.searchField !== '') {
      filters.push({ name: 'search', value: this.searchField });
    }
    this.loadData(this.page, this.pageSize, filters,  orderBy);
  }

  addEvent() {
    this.loadData(this.page, this.pageSize, []);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  /**
   * mette o toglie il certificato nell'array
   * @param key
   */
  setCertificate(key) {
    if (this.rows.length <=40) {
      const c = _.findLastIndex(this.rows, { 'k' : key });
      if (c > -1) {
        this.rows.splice(c, 1);
      } else {
        this.rows.push({ 'k' : key });
      }
    } else {
      this.openSnackBar('Non puoi scaricare più di 40 certificati alla volta', 'OK');
    }

  }

    /**
   * mette
   * @param key
   */
  addCertificate(key) {
    if (this.rows.length <=40) {
      const c = _.findLastIndex(this.rows, { 'k' : key });
      if (c > -1) {
        return;
      } else {
        this.rows.push({ 'k' : key });
      }
    } else {
      this.openSnackBar('Non puoi scaricare più di 40 certificati alla volta', 'OK');
    }

  }
  searchInRows(key) {
      const c = _.findLastIndex(this.rows, { 'k' : key });
      if (c > -1) {
        return true;
      }
      return false;
  }

  downloadMultiple() {
    this.downloading = true;
    let payload = {
      data : []
    };
    this.rows.forEach((item) => {
      payload.data.push(item.k);
    });
    this.downloadservice.downloadZip(payload)
      .subscribe((responseData:any) => {
        const a = document.createElement('a');
        const blob = new Blob([responseData], { type: responseData.type });
        a.href = URL.createObjectURL(blob);
        a.download = `certificati.zip`;
        a.click();
        this.rows = [];
        this.downloading = false;
      });
  }

  public selectAll() {  
      if (!this.status) {
      this.recordSet.forEach(element => {
        if (!element.locked) {
          this.addCertificate(element.key);
        }
      }); 
    } else {
      this.rows = [];
    }
  }

  public unableTodownload() {
    this.openSnackBar('Dowload temporaneamente bloccato. Contatta il tuo referente in LTTS', 'OK');
  }
}

import { DownloadService } from './../../services/Download.service';
import { Collection } from './../../models/jsonapi.model';
import { JsonapiService, Filter } from 'src/app/services/jsonapi.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatTableDataSource, MatSnackBar } from '@angular/material';
import * as _ from 'lodash';
@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.css']
})
export class CertificatesComponent implements OnInit {
  public tool;
  public certificates;
  public loading = false;
  displayedColumns: string[] = ['numero', 'dta_certificato', 'dta_scadenza'];
  dataSource = new MatTableDataSource<any>();
  recordSet: any = [];
  constructor(
    private jsonapiservice: JsonapiService,
    private downloadservice: DownloadService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.tool = data;
    }

  ngOnInit() {
     this.getCertificates(this.tool.id);
  }

  public getCertificates(toolId) {
    this.loading = true;
    const route = `tools/${toolId}/certificates`;
    this.jsonapiservice.customGetRequest(route).subscribe((r: Collection) => {
        r.data.forEach(item => {
          this.recordSet.push({
            numero: item.attributes.numero,
            dta_certificato: item.attributes.dta_certificato,
            dta_dc: item.attributes.dta_dc,
            dta_scadenza: item.attributes.dta_scadenza
          });
        });
        this.dataSource.data = _.orderBy(this.recordSet, ['dta_dc'], ['desc']);
        this.loading = false;
    });
  }

  public download(code) {
    this.downloadservice.downloadCertificate(code).subscribe(
      (responseData: any) => {
      const a = document.createElement('a');
      const blob = new Blob([responseData], { type: responseData.type });
      a.href = URL.createObjectURL(blob);
      if (responseData.type === 'application/vnd.ms-excel') {
        a.download = `${code}.xls`;
      } else {
        a.download = `${code}.pdf`;
      }
      a.click();
    }, (error) => {
        if (error.status === 421) {
          this.openSnackBar('Il file allegato al certificato non è stato trovato', 'OK');
        }
    });
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}

import { DocumentiComponent } from './components/documenti/documenti.component';
import { ChangepasswordComponent } from './components/changepassword/changepassword.component';
import { LoginComponent } from './components/login/login.component';
import { BodyComponent } from './components/body/body.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { DocumentiArchivioComponent } from './components/documenti-archivio/documenti-archivio.component';

const routes: Routes = [
  { path: '', component: BodyComponent, pathMatch: 'full', canActivate: [AuthGuard]  },
  { path: 'login', component: LoginComponent},
  { path: 'change-password', component: ChangepasswordComponent},
  { path: 'archivio-documenti', component: DocumentiArchivioComponent},
  { path: '**',  redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

 }

import { OauthService } from './oauth.service';
import { TokenService } from './token.service';
import { User, Credentials } from './../models/user.model';
import { Token } from './../models/token.model';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage, LocalStorageService } from 'ngx-store';
import { BehaviorSubject } from 'rxjs';
import 'rxjs-compat';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

export interface AuthState {
  token?: Token;
  user?: User;
}

@Injectable()
export class AuthService {
  user: User;
  login$ = new BehaviorSubject<AuthState>({});
  API_BASE: string = environment[environment.env].oauth_base_url;
  REDIRECT_URL = '';

  constructor(
    private myRoute: Router,
    private tokenService: TokenService,
    private localStorage: LocalStorageService,
    private oauth: OauthService

    ) { }

    login(credentials: Credentials) {
      return this.oauth.login(credentials);
    }

    getUser() {
      const token = this.getToken();
      return this.oauth.getUser(token);
    }

    getLoggedUser(): User {
      return this.localStorage.get('user');
    }

    public patchUser(opt, value) {
      this.user[opt] = value;
      this.setUser(this.user);
    }

    // tslint:disable-next-line: ban-types
    setUser(user: User): User {
       this.user = user;
       const i = this.localStorage.set('user', user);
       return i;
     }

    logout(token) {
      this.oauth.logout(token);
      this.tokenService.destroy();
      this.localStorage.remove('user');
      this.user = null;
      this.login$.next({});
    }

    public getJsonApiHeaders(): HttpHeaders  {
      const header = new HttpHeaders({
        'Content-Type': 'application/vnd.api+json',
        Accept : 'application/vnd.api+json',
        Authorization: 'Bearer ' + this.getToken()
      });
      return header;
    }

   /*  refresh(token) {
      return this.oauth.refresh(token).map((_token: Token) => {
        const _currentToken = this.tokenService.get();
        token.refresh_token = _currentToken.refresh_token;
        this.tokenService.set(_token);
        return token;
      });
    } */

    sendToken(token: string) {
      this.tokenService.set(token);
    }
    getToken() {
      return this.tokenService.get();
    }

}

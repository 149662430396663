import { JsonapiService } from './../../services/jsonapi.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material';
import { ReturnStatement } from '@angular/compiler';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  form: FormGroup;
  public error;
  public tokenExpire;
  public showpass = false;
  public version = null;
  constructor(
    private fb: FormBuilder,
    private myRoute: Router,
    private snackBar: MatSnackBar,
    private jsonapiservice: JsonapiService
  ) {
    this.form = fb.group({
      password1: ['', [Validators.required]],
      password2: ['', [Validators.required]]
    });
  }
  ngOnInit() {

  }
  changePassword() {
    if (!this.form.valid) {
      this.openSnackBar('Compila le informazioni richieste', 'OK');
      return;
    }

    if ( this.form.value.password1 !== this.form.value.password2) {
      this.openSnackBar('Le password non coincidono', 'OK');
    } else {
      const data = {
        data: {
          type: 'users',
          attributes: {
            password: this.form.value.password1
          }
        }
      };
      this.jsonapiservice.postEntityCustomPath(data, 'change-password').subscribe((responce: any) => {
        this.openSnackBar('La tua password è stata cambiata', 'OK');
        setTimeout(() => {
          this.myRoute.navigate(['/']);
        }, 1000);

      }, (err) => {
        this.openSnackBar('Qualcosa è andato storto', 'OK');
      });
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}

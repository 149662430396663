import { SelectComponent } from './components/login/select/select.component';
import { DocumentiComponent } from './components/documenti/documenti.component';
import { FormBuilder, FormsModule,  ReactiveFormsModule } from '@angular/forms';
import { LocalStorageService } from 'ngx-store';
import { LoginComponent } from './components/login/login.component';
import { BodyComponent } from './components/body/body.component';
import { HeaderComponent } from './components/header/header.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { JsonapiService } from './services/jsonapi.service';
import { AuthService } from './services/auth.service';
import { TokenService } from './services/token.service';
import { OauthService } from './services/oauth.service';
import { LocalJsonService } from './services/local.json.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatSelectionList
} from '@angular/material';
import {
  MomentDateModule,
  MomentDateAdapter
} from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
      dateInput: 'DD/MM/YYYY',
  },
  display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MM YYYY',
      dateA11yLabel: 'DD/MM/YYYY',
      monthYearA11yLabel: 'MM YYYY',
  },
};

/* import { DownloadService } from './services/Download.service'; */
import { ChangepasswordComponent } from './components/changepassword/changepassword.component';

import {
  MatPaginatorModule,
  MatTable,
  MatTableDataSource,
  MatSpinner,
  MatSortModule,
  MatCardModule,
  MatSnackBarModule,
  MatDialogModule,
  MatTableModule,
  MatProgressSpinnerModule,
  MatButtonModule,
  MatMenuModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatGridListModule,
  MatToolbarModule,
  MatBadgeModule,
  MatSidenavModule,
  MatListModule,
  MatSelectModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatChipsModule,
  MatTooltipModule,
  
} from '@angular/material';
import { CertificatesComponent } from './components/certificates/certificates.component';
import { DownloadService } from './services/Download.service';
import { DocumentiArchivioComponent } from './components/documenti-archivio/documenti-archivio.component';

const PROVIDERS = [
  AuthService,
  TokenService,
  OauthService,
  JsonapiService,
  LocalJsonService,
  LocalStorageService,
  TokenService,
  FormBuilder,
  DownloadService,
  {
    provide: MAT_DATE_LOCALE,
    useValue: {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
   }},
   { provide: MAT_DIALOG_DATA, useValue: {} },
   { provide: MatDialogRef, useValue: {} }
];

const DECLARATIONS    = [
  AppComponent,
  HeaderComponent,
  BodyComponent,
  LoginComponent,
  ChangepasswordComponent,
  CertificatesComponent,
  DocumentiArchivioComponent,
  DocumentiComponent,
  SelectComponent
];

const ENTRYCOMPONENTS = [CertificatesComponent, DocumentiComponent, SelectComponent];
const IMPORTS = [
  BrowserModule,
  AppRoutingModule,
  ReactiveFormsModule,
  FormsModule,
  BrowserAnimationsModule,
  HttpClientModule,
  MatPaginatorModule,
  MatTableModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatButtonModule,
  MatFormFieldModule,
  MatMenuModule,
  MatIconModule,
  MatInputModule,
  MatGridListModule,
  MatToolbarModule,
  MatBadgeModule,
  MatSidenavModule,
  MatListModule,
  MatSelectModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatChipsModule,
  MatTooltipModule,
  MatCardModule,
  MatSnackBarModule,
  MatDialogModule,
  FontAwesomeModule
];

const EXPORTS = [
  FormsModule,
  ReactiveFormsModule
];

@NgModule({
  declarations: DECLARATIONS,
  exports: EXPORTS,
  imports: IMPORTS,
  providers: PROVIDERS,
  entryComponents: ENTRYCOMPONENTS,
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource, ThemePalette } from '@angular/material';
import { Collection, CollectionPaginated } from 'src/app/models/jsonapi.model';
import { Paginator } from 'src/app/models/paginator.model';
import { DownloadService } from 'src/app/services/Download.service';
import { Filter, JsonapiService } from 'src/app/services/jsonapi.service';

@Component({
  selector: 'app-documenti-archivio',
  templateUrl: './documenti-archivio.component.html',
  styleUrls: ['./documenti-archivio.component.css']
})
export class DocumentiArchivioComponent implements OnInit {
  public tool;
  public certificates;
  public loading = false;
  pageSize = 10;
  page = 1;
  searchField = '';
  displayedColumns: string[] = [ 'path', 'descrizione', 'dta', 'nuovo', 'icon'];
  dataSource = new MatTableDataSource<any>();
  recordSet: any = [];
  jsonapipaginator: Paginator;
  color: ThemePalette = 'warn';
  documents = [];
  constructor(
    private jsonapiservice: JsonapiService,
    private downloadservice: DownloadService,
    private snackBar: MatSnackBar,
  ) {}
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  ngOnInit() {
    this.getDocuments(1, 20, []);
    this.searchField = window.localStorage.getItem('sortDoc');
  }

  getDocuments( page,pageSize, filters: Array<Filter>, orderby?: string) {
    this.jsonapiservice.getCollectionPaginated('documents', page, pageSize, filters, orderby).subscribe((data: CollectionPaginated) => {
      this.recordSet = [];
      this.jsonapipaginator = data.meta.page;
      data.data.forEach((item) => {
        this.recordSet.push({
          id : item.id,
          icon : item.attributes.icon,
          dta : item.attributes.data_inserimento,
          descrizione : item.attributes.descrizione,
          nuovo: item.attributes.nuovo,
          path: item.attributes.path
        });
      });
      this.dataSource.data = this.recordSet;
      this.loading = false;
    });
  }

  downloadFile(id) {
    this.downloadservice.downloadDocument(id).subscribe(
      (responseData: any) => {
      const a = document.createElement('a');
      const blob = new Blob([responseData], { type: responseData.type });
      a.href = URL.createObjectURL(blob);
      a.download = `${id}`;
      a.click();
    }, (error) => {

    });
  }

  changePage($event) {
    const filters: Array<Filter> = [];
    this.page = $event.pageIndex + 1;
    this.pageSize = $event.pageSize;
    this.getDocuments(this.page, this.pageSize, filters, null);
  }
  sortData($event) {
    const orderBy = $event.active;
    const direction = $event.direction === 'asc' ? '' : '-';
    const filters: Array<Filter> = [];
    this.getDocuments(this.page, this.pageSize, filters,  orderBy);
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
  getIcon(ext: string) {
    return '/assets/file-iconset/32px/' + ext + '.png';
  }
}


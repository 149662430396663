import { LocalStorageService } from 'ngx-store';
import { OauthService } from './../../../services/oauth.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Conti } from 'src/app/models/user.model';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit {

  conti: Array<Conti>;
  
  constructor( @Inject(MAT_DIALOG_DATA) public data: Array<Conti>,
    private oauthservice: OauthService,
    private localstorage: LocalStorageService
  ) {
      this.conti = data;
  }


  public selezionaSede(conto) {
    this.oauthservice.selectCode(conto.conto).subscribe((data) => {
      this.localstorage.set('sedeselezionata',conto.citta+ ', ' +conto.address );
      console.log(data);
      window.location.href = '/';
    });
  }


  ngOnInit() {
    
  }

}

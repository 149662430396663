 
import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from '@angular/http';
import { Injectable, Injector } from '@angular/core';
import { LocalStorageService } from 'ngx-store';

@Injectable()
export class OauthService {

  API_BASE: string = environment[environment.env].oauth_base_url;
  CLIENT_ID = environment[environment.env].client_id;
  CLIENT_SECRET = environment[environment.env].client_secret;
  REDIRECT_URL = '';

  constructor(private injector: Injector, private localstorage: LocalStorageService) { }

  login(credentials) {
    return this.injector.get(HttpClient).post(`${this.API_BASE}/token`, {
      'grant_type': 'password',
      'client_id': this.CLIENT_ID,
      'client_secret': this.CLIENT_SECRET,
      'username': credentials.username,
      'password': credentials.password,
      "scope" : ""
    });
  }

  refresh(token) {
    return this.injector.get(HttpClient).post(`${this.API_BASE}/token`, {
      'grant_type': 'refresh_token',
      'refresh_token': token,
      'client_id': this.CLIENT_ID,
      'client_secret': this.CLIENT_SECRET,
    });
  }

  getUser(token) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      , 'Authorization': 'Bearer ' + token
    });
    const options = { headers: headers };
    return this.injector.get(HttpClient).get(`${environment[environment.env].base_url}/user`, options);
  }

  logout(token) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      , 'Authorization': 'Bearer ' + token
    });
    const options = { headers: headers };
    this.injector.get(HttpClient)
      .get(`${environment[environment.env].base_url}/logout`, options)
      .subscribe(
        () => { console.log('LOGOUT'); },
        () => {}
      );
  }




  selectCode(code) {
    const token   = this.localstorage.get('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
      , 'Authorization': 'Bearer ' + token
    });
    const options = { headers: headers };
    return this.injector.get(HttpClient)
      .get(`${environment[environment.env].base_url}/select/${code}`, options)
  }

}

import * as moment from 'moment';

export interface IUser {
  data: JsonApiData;
}

export interface JsonApiData {
  id: number;
  type: string;
  attributes: Attributes;
}

export interface Conti {
  conto : string;
  address: string;
  citta: string;
}

export interface Attributes {
  conti: Array<Conti>;
  id: number;
  ragione_sociale: string;
  email: string;
  customer_code: string;
  created_at: string;
  updated_at: string;
}

export class User implements IUser {
  public data: JsonApiData;
  constructor(userObj: IUser) {
    Object.assign(this, userObj);
  }

  public getId() {
    if (this.data && this.data.id) {
      return this.data.id;
    }
  }

  public getEmail() {
    return this.data.attributes.email;
  }

}

export interface Credentials {
  username: string;
  password?: string;
}

import { LocalStorageService } from 'ngx-store';
import { DocumentiComponent } from './../documenti/documenti.component';
import { Collection } from './../../models/jsonapi.model';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { JsonapiService, Filter } from 'src/app/services/jsonapi.service';
import { User } from 'src/app/models/user.model';
import { Router } from '@angular/router';
import { filter } from 'rxjs-compat/operator/filter';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public user: User = null;
  documents;
  numberOfDocuments;
  sede: null;
  constructor(
    private oauth: AuthService,
    private jsonapiservice: JsonapiService,
    private myRoute: Router,
    public dialog: MatDialog,
    private localstorage: LocalStorageService
  ) { }

  ngOnInit() {
    if (this.oauth.getToken()) {
      this.getDocuments();
      this.user = new User(this.oauth.getLoggedUser());
      this.sede = this.localstorage.get('sedeselezionata');
    }
  }

  getDocuments() {
    const filters: Array<Filter> = [];
    filters.push({ name : 'nuovo', value : '1'});
    this.jsonapiservice.getCollection('documents', filters).subscribe((success: Collection) => {
      this.numberOfDocuments =   success.data.length;
      this.documents = success.data;
    });
  }

  showDocuments() {
    const dialogRef = this.dialog.open(DocumentiComponent, {
     //   height: '400px',
        width: '600px',
      data: this.documents,
    });
  }

  logout() {
    this.oauth.logout(this.oauth.getToken());
    window.location.href = '/';
  }
  certificati() {
    this.myRoute.navigate(['']);
  }
  documenti() {
    this.myRoute.navigate(['archivio-documenti']);
  }

  changePassword() {
    this.myRoute.navigate(['change-password']);
  }
  }

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DownloadService } from 'src/app/services/Download.service';

@Component({
  selector: 'app-documenti',
  templateUrl: './documenti.component.html',
  styleUrls: ['./documenti.component.css']
})
export class DocumentiComponent implements OnInit {
  documents = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private downloadservice: DownloadService
    ) {
      this.documents = this.data;
   }

  download(id) {
    this.downloadservice.downloadDocument(id).subscribe(
      (responseData: any) => {
      const a = document.createElement('a');
      const blob = new Blob([responseData], { type: responseData.type });
      a.href = URL.createObjectURL(blob);
      a.download = `${id}`;
      a.click();
    }, (error) => {

    });
  }
  getIcon(ext: string) {
    return '/assets/file-iconset/16px/' + ext + '.png';
  }

  ngOnInit() {
    
  }

}
